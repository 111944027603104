<template>
    <section class="content overlay-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="box">
          <div class="box-body print-receipt">
            <div class="row">
                <div class="col-md-12 mbottom-15 form-alert-message">
                    <div class="alert alert-success alert-dismissible">
                        <button type="button" class="close">×</button>
                        <div class="message-container">
                        <div class="icon">
                            <i class="icon fa fa-ban"></i>
                        </div>
                        <div class="message">
                            <strong>Thank You!</strong> Your payment has been made.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <table class="table">
                     <tr> 
                        <td><strong>Reference Number</strong></td>
                        <td>#9898989</td>
                    </tr>
                    <tr> 
                        <td><strong>Date of Payment</strong></td>
                        <td>7/14/23</td>
                    </tr>
                    <tr> 
                        <td><strong>Amount Paid</strong></td>
                        <td>$200.45</td>
                    </tr>
                    <tr> 
                        <td><strong>Payment Method</strong></td>
                        <td>Visa (####-####-####-8909)</td>
                    </tr>
                    <tr> 
                        <td><strong>Confirmation Number</strong></td>
                        <td>09809809</td>
                    </tr>
                   
                 </table>
              </div>
              <div class="col-md-6">
                
              </div>
             
            </div>
          </div> 
        </div> 
      </div> 
    </div> 
    </section>
  </template>
  
  <style src="@vueform/toggle/themes/default.css"></style>
  <script>
  import Breadcrumb from '../components/Breadcrumb.vue';
  import Multiselect from '@vueform/multiselect'
  import Vue from 'vue';
  import { reactive,ref, onMounted } from 'vue'
  export default {
    name: 'Payment Collection',
     components: {
      Multiselect,
    },
    setup(){ 
    }
  
  }
  </script>
  